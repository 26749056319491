import classNames from 'classnames'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import {
  Button,
  Label,
  ListBox,
  ListBoxItem,
  Popover,
  Select,
  SelectValue,
} from 'react-aria-components'
import InputError from '../InputError'
import InputLabel from '../InputLabel'
import Spinner from '../Spinner'
import ToggleArrow from '../ToggleArrow'
import styles from './Dropdown.module.css'

const Dropdown = forwardRef(
  (
    {
      label,
      labelClassName,
      ariaLabel,
      options = [],
      required,
      initialValue,
      value,
      onChange,
      errorText,
      hasError = !!errorText,
      className,
      disabled,
      isLoading,
      ...props
    },
    ref
  ) => {
    return (
      <Select
        className={classNames(styles.select, className)}
        selectedKey={normalizeValue(value)}
        defaultSelectedKey={normalizeValue(initialValue)}
        onSelectionChange={(val) => onChange(parseValue(val))}
        isDisabled={disabled || isLoading}
        placeholder=" "
        aria-label={ariaLabel}
        {...props}
      >
        {({ isOpen, isFocused }) => (
          <>
            {Boolean(label) && (
              <Label
                elementType={InputLabel}
                required={required}
                className={labelClassName}
              >
                {label}
              </Label>
            )}
            <Button
              ref={ref}
              className={classNames(
                styles.button,
                isFocused && 'outline-auto',
                hasError
                  ? 'bg-input-error-bg border-error'
                  : 'bg-white border-mid-gray',
                'w-full py-xxs px-sm flex items-center border rounded-sm'
              )}
            >
              <SelectValue className="font-medium text-base text-start line-clamp-1" />
              <div className="ml-auto pl-xxs">
                {isLoading ? (
                  <Spinner size={16} />
                ) : (
                  <ToggleArrow expanded={isOpen} />
                )}
              </div>
            </Button>
            <InputError>{errorText}</InputError>
            <Popover offset={5}>
              <ListBox
                className={classNames(
                  styles.options,
                  'bg-white divide-y divide-black-15 rounded-sm shadow-md overflow-auto'
                )}
              >
                {options.map((option) => {
                  const stringValue = normalizeValue(option.value)
                  return (
                    <ListBoxItem
                      key={stringValue}
                      id={stringValue}
                      className={classNames(
                        styles.option,
                        'font-medium text-base py-xs px-sm hover:bg-hover focus:bg-hover outline-none cursor-pointer'
                      )}
                    >
                      {/* <Select/> shows warning if textValue is empty */}
                      {option.text || ' '}
                    </ListBoxItem>
                  )
                })}
              </ListBox>
            </Popover>
          </>
        )}
      </Select>
    )
  }
)

// support option with `null` value
const normalizeValue = (value) => (value === null ? '' : value)
const parseValue = (value) => (value === '' ? null : value)

Dropdown.displayName = 'Dropdown'

Dropdown.propTypes = {
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasError: PropTypes.bool,
  errorText: PropTypes.string,
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
}

export default Dropdown
