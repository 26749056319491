import { Outlet } from 'react-router-dom'
import ScrollToTop from '../../common/components/ScrollToTop'
import { usePageViewTagEvents } from '../../common/tagManager/usePageViewTagEvents'

const Root = () => {
  usePageViewTagEvents()
  return (
    <>
      <ScrollToTop />
      <Outlet />
    </>
  )
}

export default Root
