import { Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import DEMO_EN from '../../../assets/demo-en.png'
import DEMO_FI from '../../../assets/demo-fi.png'
import DEMO_SV from '../../../assets/demo-sv.png'
import AdminPage from '../../../common/components/AdminPage'
import Background from '../../../common/components/Background'
import { ExternalLinkButton } from '../../../common/components/Button'
import ErrorBox from '../../../common/components/ErrorBox'
import FiftyFifty from '../../../common/components/FiftyFifty'
import Shimmer from '../../../common/components/Shimmer'
import SuspenseImage from '../../../common/components/SuspenseImage'
import { H1, P, Ul } from '../../../common/components/Typography'
import WideContent from '../../../common/components/WideContent'
import { ENGLISH, SWEDISH } from '../../../common/constants'
import { COMPANY_ADMIN_LOGIN_URL } from '../constants'
import './HomeScreen.scss'
import { ERROR_NOT_ADMIN } from './LoginSuccess'

const HomeScreen = () => {
  const [t, i18n] = useTranslation()
  const [searchParams] = useSearchParams()
  const error = searchParams.get('error')

  return (
    <AdminPage backgroundColor="#ffffff">
      <Background>
        <WideContent
          className="anonymous__home-screen"
          withoutHorizontalPadding
          transparent
        >
          {error === ERROR_NOT_ADMIN && (
            <ErrorBox style={{ marginBottom: '40px' }}>
              <P style={{ marginTop: 0, marginBottom: 0 }}>
                {t('ANONYMOUS:HOME_SCREEN_OLD.ERROR_NOT_ADMIN')}
              </P>
            </ErrorBox>
          )}
          <FiftyFifty
            left={
              <>
                <H1 fontSize="huge">{t('ANONYMOUS:HOME_SCREEN.HEADING')}</H1>
                <P style={{ marginTop: 0 }}>
                  {t('ANONYMOUS:HOME_SCREEN.BODY_1')}
                </P>
                <Ul
                  items={[
                    t('ANONYMOUS:HOME_SCREEN.BULLET_1'),
                    t('ANONYMOUS:HOME_SCREEN.BULLET_2'),
                    t('ANONYMOUS:HOME_SCREEN.BULLET_3'),
                  ]}
                  compact
                  className="my-0"
                />
                <P>{t('ANONYMOUS:HOME_SCREEN.BODY_2')}</P>
                <div>
                  <ExternalLinkButton
                    data-cy="login"
                    href={`${COMPANY_ADMIN_LOGIN_URL}?redirectTo=authenticated`}
                    style={{ marginBottom: '20px' }}
                  >
                    {t('ANONYMOUS:HOME_SCREEN.LOG_IN')}
                  </ExternalLinkButton>
                </div>
                <P>{t('ANONYMOUS:HOME_SCREEN.BODY_3')}</P>
                <div>
                  <ExternalLinkButton
                    secondary
                    href={t(
                      'ANONYMOUS:HOME_SCREEN.READ_MORE_ABOUT_COMPANY_PORTAL_URL'
                    )}
                  >
                    {t('ANONYMOUS:HOME_SCREEN.READ_MORE_ABOUT_COMPANY_PORTAL')}
                  </ExternalLinkButton>
                </div>
              </>
            }
            right={
              <>
                <div className="anonymous__home-screen__demo">
                  <Suspense
                    fallback={
                      <Shimmer active>
                        <div className="anonymous__home-screen__demo-image" />
                      </Shimmer>
                    }
                  >
                    <SuspenseImage
                      className="anonymous__home-screen__demo-image"
                      src={getDemoImageByLocale(i18n.language)}
                    />
                  </Suspense>
                </div>
              </>
            }
            leftStyle={{ paddingRight: 0 }}
            hideSeparator
          />
        </WideContent>
      </Background>
    </AdminPage>
  )
}

const getDemoImageByLocale = (locale) => {
  switch (locale) {
    case SWEDISH.code:
      return DEMO_SV

    case ENGLISH.code:
      return DEMO_EN

    default:
      return DEMO_FI
  }
}

export default HomeScreen
