import PropTypes from 'prop-types'
import { forwardRef, useEffect, useState } from 'react'
import TextInput from './TextInput'

// This input component supports both periods and commas as decimal points and strips out
// other characters.
//
// You can define the maximum number of decimal places with `maxDecimalPlaces`.
// Defaults to 2 for monetary values such as 9.95.
//
// The `onChange` event emits values as valid numbers (not strings).

const DecimalInput = forwardRef(
  (
    {
      onChange,
      maxDecimalPlaces = 2,
      defaultValue = '',
      convertToNumberOnInit = true,
      ...props
    },
    ref
  ) => {
    const [proxyValue, setProxyValue] = useState(String(defaultValue))
    const [hasInitialized, setHasInitialized] = useState(false)

    useEffect(() => {
      if (!hasInitialized) {
        setHasInitialized(true)
      }

      if (!convertToNumberOnInit && !hasInitialized) {
        return
      }

      if (proxyValue === '') {
        onChange('')
        return
      }

      const proxyValueAsNumber = toNumber(proxyValue)

      onChange(proxyValueAsNumber)
    }, [proxyValue])

    return (
      <TextInput
        ref={ref}
        value={proxyValue}
        onChange={({ target }) =>
          setProxyValue(handleDecimalChange(target.value, maxDecimalPlaces))
        }
        {...props}
      />
    )
  }
)

DecimalInput.displayName = 'DecimalInput'

DecimalInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  maxDecimalPlaces: PropTypes.number,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  convertToNumberOnInit: PropTypes.bool,
}

export const handleDecimalChange = (value, maxDecimalPlaces) => {
  let newValue = value
    .replace(/[^0-9.,]/g, '') // strip all characters except numbers, periods, and commas
    .replace(/\./, 'PERIOD') // save first period
    .replace(/,/, 'COMMA') // save first comma
    .replace(/[.,]/g, '') // strip all remaining periods and commas
    .replace('PERIOD', '.') // restore first period
    .replace('COMMA', ',') // restore first comma

  // Strip commas if a period already exists
  if (newValue.includes('.') && newValue.includes(',')) {
    newValue = newValue.replace(/,/g, '')
  }

  // Strip excess decimal places
  const hasComma = newValue.includes(',')
  const [, decimals] = newValue.replace(',', '.').split('.')
  if (decimals?.length > maxDecimalPlaces) {
    const fixedNewValue = Number(newValue.replace(',', '.')).toFixed(
      maxDecimalPlaces
    )

    newValue = hasComma ? fixedNewValue.replace('.', ',') : fixedNewValue
  }

  return newValue
}

export const toNumber = (value) => Number(value.replace(',', '.'))

export default DecimalInput
