import classnames from 'classnames'
import PropTypes from 'prop-types'

const WideContent = ({
  children,
  withTopMargin,
  withBottomMargin,
  withVerticalPadding,
  withoutHorizontalPadding,
  transparent,
  className,
  style,
  ...props
}) => (
  <div
    className={classnames(
      'w-full max-desktop:px-lg max-w-wide-content rounded-sm',
      {
        'mt-xl': withTopMargin,
        'mb-xl': withBottomMargin,
        'py-xl': withVerticalPadding,
        'desktop:px-xxl': !withoutHorizontalPadding && !transparent,
        'bg-white': !transparent,
      },
      className
    )}
    style={style}
    {...props}
  >
    {children}
  </div>
)

WideContent.propTypes = {
  children: PropTypes.any,
  withTopMargin: PropTypes.bool,
  withBottomMargin: PropTypes.bool,
  withVerticalPadding: PropTypes.bool,
  withoutHorizontalPadding: PropTypes.bool,
  transparent: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
}

export default WideContent
