import { observer } from 'mobx-react'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import AutocompleteAddress from '../../../common/components/AutocompleteAddress'
import BillingMethodInput from '../../../common/components/BillingMethodInput'
import { Button } from '../../../common/components/Button'
import CheckboxWithLabel from '../../../common/components/CheckboxWithLabel'
import Dropdown from '../../../common/components/Dropdown/Dropdown'
import FormErrors from '../../../common/components/FormErrors'
import Hr from '../../../common/components/Hr'
import Page from '../../../common/components/Page'
import TextInput from '../../../common/components/TextInput'
import { H1, H2, Ol, P } from '../../../common/components/Typography'
import WhiteBox from '../../../common/components/WhiteBox'
import { Redirect } from '../../routes/components/Link'
import ROUTES from '../../routes/routes'
import validateSelfServiceForm from '../helpers/validateSelfServiceForm'
import applicationFormStore from '../stores/applicationFormStore'

const ApplicationScreen = () => {
  const [t] = useTranslation()
  const [errors, setErrors] = useState({})
  const [isSuccess, setIsSuccess] = useState(false)

  const next = async (form) => {
    const errors = validateSelfServiceForm(form, t)

    setErrors(errors)

    if (hasErrors(errors)) {
      return
    }

    setIsSuccess(true)
  }

  if (isSuccess) {
    return (
      <Redirect route={ROUTES.SELF_SERVICE.APPLICATION_CONFIRMATION_SCREEN} />
    )
  }

  return (
    <Page>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <WhiteBox>
        <H1>{t('SELF_SERVICE:SELF_SERVICE_SCREEN.HEADING')}</H1>
        <Ol
          items={[
            t('SELF_SERVICE:SELF_SERVICE_SCREEN.INSTRUCTION_1'),
            t('SELF_SERVICE:SELF_SERVICE_SCREEN.INSTRUCTION_2'),
            t('SELF_SERVICE:SELF_SERVICE_SCREEN.INSTRUCTION_3'),
            t('SELF_SERVICE:SELF_SERVICE_SCREEN.INSTRUCTION_4'),
            t('SELF_SERVICE:SELF_SERVICE_SCREEN.INSTRUCTION_5'),
            t('SELF_SERVICE:SELF_SERVICE_SCREEN.INSTRUCTION_6'),
          ]}
          style={{
            listStylePosition: 'outside',
            paddingInlineStart: '20px',
          }}
        />
        <P>{t('SELF_SERVICE:SELF_SERVICE_SCREEN.INTRO')}</P>
        <Hr style={{ marginTop: '30px', marginBottom: '30px' }} />
        <H2 fontSize="mormal">
          {t('SELF_SERVICE:SELF_SERVICE_SCREEN.COMPANY_DETAILS')}
        </H2>
        <TextInput
          label={t('COMMON:COMPANY_NAME')}
          required
          value={applicationFormStore.form.companyName}
          hasError={Boolean(errors.companyName)}
          onChange={({ target }) =>
            applicationFormStore.setValues({ companyName: target.value })
          }
        />
        <TextInput
          label={t('COMMON:BUSINESS_ID')}
          required
          value={applicationFormStore.form.businessId}
          hasError={Boolean(errors.businessId)}
          onChange={({ target }) =>
            applicationFormStore.setValues({ businessId: target.value })
          }
        />
        <Dropdown
          label={t('SELF_SERVICE:SELF_SERVICE_SCREEN.NUMBER_OF_EMPLOYEES')}
          required
          placeholder={t('COMMON:SELECT')}
          options={[
            {
              value: COMPANY_SIZE_SMALL,
              text: formatNumberOfEmployees(COMPANY_SIZE_SMALL, t),
            },
            {
              value: COMPANY_SIZE_MEDIUM,
              text: formatNumberOfEmployees(COMPANY_SIZE_MEDIUM, t),
            },
            {
              value: COMPANY_SIZE_LARGE,
              text: formatNumberOfEmployees(COMPANY_SIZE_LARGE, t),
            },
          ]}
          onChange={(numberOfEmployees) =>
            applicationFormStore.setValues({ numberOfEmployees })
          }
          value={applicationFormStore.form.numberOfEmployees}
          hasError={Boolean(errors.numberOfEmployees)}
        />
        <Hr style={{ marginTop: '35px', marginBottom: '30px' }} />
        <H2 fontSize="mormal">{t('COMMON:OFFICE_ADDRESS')}</H2>
        <AutocompleteAddress
          value={applicationFormStore.form.officeStreetAddress}
          label={t('COMMON:STREET_ADDRESS')}
          required
          hasError={Boolean(errors.officeStreetAddress)}
          onType={(value) =>
            applicationFormStore.setValues({
              officeStreetAddress: value,
              officePostalCode: '',
              officeCity: '',
            })
          }
          onSelect={(option) =>
            applicationFormStore.setValues({
              officeStreetAddress: option.street ?? '',
              officePostalCode: option.postalCode ?? '',
              officeCity: option.city ?? '',
            })
          }
          onDismiss={() =>
            applicationFormStore.setValues({
              officeStreetAddress: '',
              officePostalCode: '',
              officeCity: '',
            })
          }
        />
        <TextInput
          label={t('COMMON:POSTAL_CODE')}
          required
          value={applicationFormStore.form.officePostalCode}
          hasError={Boolean(errors.officePostalCode)}
          onChange={({ target }) =>
            applicationFormStore.setValues({ officePostalCode: target.value })
          }
        />
        <TextInput
          label={t('COMMON:CITY')}
          required
          value={applicationFormStore.form.officeCity}
          hasError={Boolean(errors.officeCity)}
          onChange={({ target }) =>
            applicationFormStore.setValues({ officeCity: target.value })
          }
        />
        <Hr style={{ marginTop: '40px', marginBottom: '30px' }} />
        <H2 fontSize="mormal">
          {t('SELF_SERVICE:SELF_SERVICE_SCREEN.MAILING_ADDRESS')}
        </H2>
        <CheckboxWithLabel
          checked={applicationFormStore.form.useOfficeAsMailingAddress}
          onClick={() =>
            applicationFormStore.setValues({
              useOfficeAsMailingAddress:
                !applicationFormStore.form.useOfficeAsMailingAddress,
            })
          }
          label={t('SELF_SERVICE:SELF_SERVICE_SCREEN.SAME_AS_OFFICE_ADDRESS')}
          style={{ marginBottom: '30px' }}
        />
        {!applicationFormStore.form.useOfficeAsMailingAddress && (
          <>
            <TextInput
              label={t('COMMON:STREET_ADDRESS')}
              required
              value={applicationFormStore.form.mailingStreetAddress}
              hasError={Boolean(errors.mailingStreetAddress)}
              onChange={({ target }) =>
                applicationFormStore.setValues({
                  mailingStreetAddress: target.value,
                })
              }
            />
            <TextInput
              label={t('COMMON:POSTAL_CODE')}
              required
              value={applicationFormStore.form.mailingPostalCode}
              hasError={Boolean(errors.mailingPostalCode)}
              onChange={({ target }) =>
                applicationFormStore.setValues({
                  mailingPostalCode: target.value,
                })
              }
            />
            <TextInput
              label={t('COMMON:CITY')}
              required
              value={applicationFormStore.form.mailingCity}
              hasError={Boolean(errors.mailingCity)}
              onChange={({ target }) =>
                applicationFormStore.setValues({ mailingCity: target.value })
              }
            />
          </>
        )}
        <Hr style={{ marginTop: '40px', marginBottom: '30px' }} />
        <H2 fontSize="mormal">{t('COMMON:BILLING_METHODS.BILLING_METHOD')}</H2>
        <BillingMethodInput
          values={applicationFormStore.form}
          errors={errors}
          onChange={(values) => applicationFormStore.setValues(values)}
        />
        <Hr style={{ marginTop: '30px', marginBottom: '30px' }} />
        <H2 fontSize="mormal">
          {t('SELF_SERVICE:SELF_SERVICE_SCREEN.CONTACT')}
        </H2>
        <P>{t('SELF_SERVICE:SELF_SERVICE_SCREEN.CONTACT_DESCRIPTION')}</P>
        <TextInput
          label={t('SELF_SERVICE:SELF_SERVICE_SCREEN.CONTACT_NAME')}
          required
          value={applicationFormStore.form.contactName}
          hasError={Boolean(errors.contactName)}
          onChange={({ target }) =>
            applicationFormStore.setValues({ contactName: target.value })
          }
        />
        <TextInput
          label={t('COMMON:WORK_EMAIL')}
          required
          value={applicationFormStore.form.contactWorkEmail}
          hasError={Boolean(errors.contactWorkEmail)}
          onChange={({ target }) =>
            applicationFormStore.setValues({ contactWorkEmail: target.value })
          }
        />
        <TextInput
          label={t('COMMON:PHONE')}
          required
          value={applicationFormStore.form.contactPhoneNumber}
          placeholder="+358"
          hasError={Boolean(errors.contactPhoneNumber)}
          onChange={({ target }) =>
            applicationFormStore.setValues({
              contactPhoneNumber: target.value === '' ? '+' : target.value,
            })
          }
        />
        <Hr style={{ marginTop: '40px', marginBottom: '40px' }} />
        {hasErrors(errors) && (
          <FormErrors
            errors={Object.keys(errors).map((key) => errors[key])}
            style={{ marginBottom: '30px' }}
          />
        )}
        <Button onClick={() => next(applicationFormStore.form)}>
          {t('COMMON:CONTINUE')}
        </Button>
      </WhiteBox>
    </Page>
  )
}

const hasErrors = (errors) => Boolean(Object.keys(errors).length)

const COMPANY_SIZE_SMALL = '1-19'
const COMPANY_SIZE_MEDIUM = '20-49'
const COMPANY_SIZE_LARGE = '50+'

export const formatNumberOfEmployees = (numberOfEmployees, t) => {
  const valueToLabel = {
    [COMPANY_SIZE_SMALL]: '1–19',
    [COMPANY_SIZE_MEDIUM]: '20–49',
    [COMPANY_SIZE_LARGE]: t('COMMON:X_OR_MORE', { number: 50 }),
  }

  return valueToLabel[numberOfEmployees]
}

export default observer(ApplicationScreen)
