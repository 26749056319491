import PropTypes from 'prop-types'

const InputError = ({ children, mt = 'sm' }) => {
  return children ? (
    <p className={`text-error mb-0 mt-${mt}`}>{children}</p>
  ) : null
}

InputError.propTypes = {
  children: PropTypes.node,
  mt: PropTypes.string,
}

export default InputError
