export const REACT_APP_ENV_LOCAL = 'local'
export const REACT_APP_ENV_DEV = 'dev'
export const REACT_APP_ENV_UAT = 'uat'
export const REACT_APP_ENV_PRODUCTION = 'production'

export const USER_ROLES = {
  ADMIN: 'ADMIN',
  HSL_ADMIN: 'HSL_ADMIN',
  BENEFICIARY: 'BENEFICIARY',
}

export const FINNISH = {
  code: 'fi',
  name: 'Suomi',
}

export const SWEDISH = {
  code: 'sv',
  name: 'Svenska',
}

export const ENGLISH = {
  code: 'en',
  name: 'English',
}

export const LANGUAGES = [FINNISH, SWEDISH, ENGLISH]

export const DEFAULT_LANGUAGE = FINNISH

export const EMAIL_VERIFICATION_ERROR_CODES = [
  'verification-request-required', // when the body is missing
  'email-address-required', // when the email value is missing
  'token-required', // when the token value is missing
  'email-address-invalid', // when the email address is invalid
  'invalid-email-token-pair', // when there is no invitation with this email + token combination
  'email-token-pair-used', // when the user has already used up their invitation (work email + token combination)
  'invite-revoked', // when the invitation has been withdrawn/canceled by an admin
  'email-exists', // when the email address is already in use
]

export const YYYY_MM_DD = 'yyyy-MM-dd'

export const DOMICILE_CLASS_HSL = 'hsl' // HSL area
export const DOMICILE_CLASS_NEIGHBORING_CITIES = 'hsl-neighboring' // cities surrounding the HSL area (DEPRECATED ON 1.1.2023)
export const DOMICILE_CLASS_NEIGHBORING_LIMITED = 'hsl-neighboring-limited' // Järvenpää or something
export const DOMICILE_CLASS_NEIGHBORING_SPECIAL = 'hsl-neighboring-special' // cities surrounding the HSL area

export const ZONES_EXCLUDED_FROM_NEIGHBOING_LIMITED = ['ABCD', 'BCD', 'CD']

export const CUSTOMER_TYPE_COMMUTER_BENEFIT = 'COMMUTER_BENEFIT'
export const CUSTOMER_TYPE_BUSINESS_TRIP = 'BUSINESS_TRIP'

export const BENEFIT_TYPE_ANNUAL_BALANCE = 'ANNUAL_BALANCE'
export const BENEFIT_TYPE_SEASON_TICKET = 'SEASON_TICKET'
export const BENEFIT_TYPE_BUSINESS_TRIP_ANNUAL_BALANCE =
  'BUSINESS_TRIP_ANNUAL_BALANCE'
export const BENEFIT_TYPE_SHOOL_TICKET = 'SCHOOL_TICKET'

export const AGE_GROUP_ADULT = 'ADULT'
export const AGE_GROUP_CHILD = 'CHILD'

export const EM_DASH = '—'
export const EN_DASH = '–'
export const ARROW_RIGHT_CHARACTER = '➝'

export const SALES_SUPPORT_PHONE_NUMBER = '09 4766 4303'
export const SALES_SUPPORT_PHONE_NUMBER_INTL = '+358947664303'

export const SEASON_TICKET_PRICE = 70.6
export const DEFAULT_PAGE_SIZE = 10

export const ERROR_CODE_TO_TRANSLATION = {
  'company-contract-not-signed':
    'COMPANY:INVITATION_FORM.CONTRACT_NOT_SIGNED_ERROR',
  'company-benefits-not-enabled':
    'COPMANY:INVITATION_FORM.BENEFITS_NOT_ENABLED_ERROR',
  'company-purchases-not-enabled':
    'COMPANY:INVITATION_FORM.PURCHASES_NOT_ENABLED_ERROR',
}

export const BALANCE_INTERVAL_YEARLY = 'YEAR'
export const BALANCE_INTERVAL_MONTHLY = 'MONTH'

export const FIRST_DAY_OF_MONTH = 'FIRST'

export const BALANCE_RENEWAL_SAME_AS_PREVIOUS_YEAR = 'SAME_AS_LAST'

export const BALANCE_RENEWAL_NEW_BALANCE = 'CUSTOM_COMPANY_WIDE'
