import ContainerSpinner from '@hsl-fi/container-spinner'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Navigate,
  Route,
  useMatch,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import {
  DEFAULT_LANGUAGE,
  LANGUAGES,
  USER_ROLES,
} from '../../../common/constants'
import { setCookieConsentLanguage } from '../../../common/hooks/useCookieConsent'
import useOnChange from '../../../common/hooks/useOnChange'
import apiStore from '../../../common/stores/apiStore'
import uiMessageStore from '../../../common/stores/uiMessageStore'
import userStore from '../../../common/stores/userStore'
import { pushTagEvent } from '../../../common/tagManager/tagManager'
import { ERROR_NOT_ADMIN } from '../../anonymous/components/LoginSuccess'
import useCurrentCompany from '../../company/components/hooks/useCurrentCompany'
import GuestCompanyAdminBar from '../../supervisor/components/GuestCompanyAdminBar'
import { COMPANY_TOS_STATE_ACCEPTED } from '../../supervisor/constants'
import ROUTES, {
  isCorrectPathForLanguage,
  translatePathToLanguage,
} from '../routes'
import AcceptTOSModal from './AcceptTOSModal/AcceptTOSModal'
import { Redirect } from './Link'

const ComponentPropType = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.elementType,
  PropTypes.func,
])

export const BasicRouteRenderer = observer(
  ({ component, absolutePath, language }) => {
    const { pattern } = useMatch({ path: absolutePath })
    const [, i18n] = useTranslation()
    const params = useParams()
    const Component = component
    const [searchParams] = useSearchParams()

    useEffect(() => {
      i18n.changeLanguage(language)
      setCookieConsentLanguage(language)

      apiStore.fetchCsrfToken()
    }, [i18n, language])

    // Redirects to correct language page if lang + route mismatch.
    if (!isCorrectPathForLanguage(pattern.path, language)) {
      const correctlyTranslatedRoute = translatePathToLanguage(
        pattern.path,
        params,
        Object.fromEntries(searchParams),
        language
      )

      return <Navigate to={correctlyTranslatedRoute} />
    }

    if (apiStore.isFetchingCsrfToken) {
      return <ContainerSpinner visible={true} />
    }

    return <Component />
  }
)

// The route wrappers CompanyAdminRoute and SupervisorRoute allow you
// to restrict components to certain user roles. They check if the role
// requirement is met, or else redirect the user back to the home screen
// relevant to the user role.

const RolePropType = PropTypes.oneOf([
  USER_ROLES.ADMIN,
  USER_ROLES.HSL_ADMIN,
  USER_ROLES.BENEFICIARY,
])

const PrivateRouteRenderer = observer(
  ({ component, role, absolutePath, language, ...rest }) => {
    const { pattern } = useMatch({ path: absolutePath })
    const params = useParams()
    const [t, i18n] = useTranslation()
    const [isInitialized, setIsInitialized] = useState(false)
    const [searchParams] = useSearchParams()
    const Component = component

    const { data: companyData } = useCurrentCompany()

    // because company data is updated so slowly it is not enough to just invalidate the data
    // so it is necessary to manually set internal tos state after succesfully approved tos
    const [isTosAccepted, setIsTosAccepted] = useState(true)
    useOnChange(() => {
      if (companyData) {
        setIsTosAccepted(companyData?.tosState === COMPANY_TOS_STATE_ACCEPTED)
      }
    }, companyData)

    useOnChange(() => {
      if (companyData) {
        pushTagEvent({ beneficiaries_count: companyData.beneficiaryCount })
      }
    }, companyData?.beneficiaryCount)

    useEffect(() => {
      i18n.changeLanguage(language)
      setCookieConsentLanguage(language)

      const initialize = async () => {
        try {
          setIsInitialized(false)

          if (!userStore.user.role) {
            const user = await apiStore.fetchUser()

            userStore.user = {
              ...userStore.user,
              role: user.role,
            }
          }

          await apiStore.fetchCsrfToken()

          setIsInitialized(true)
        } catch (e) {
          uiMessageStore.addError(t('COMMON:ERRORS.SERVER_ERROR'), e)
        }
      }

      initialize()
    }, [i18n, language, role])

    // Redirects to correct language page if lang + route mismatch.
    if (!isCorrectPathForLanguage(pattern.path, language)) {
      const correctlyTranslatedRoute = translatePathToLanguage(
        pattern.path,
        params,
        Object.fromEntries(searchParams),
        language
      )

      return <Navigate to={correctlyTranslatedRoute} />
    }

    if (!isInitialized) {
      return <ContainerSpinner visible={true} />
    }

    if (!userStore.user.role || userStore.isBeneficiary) {
      return <Redirect route={ROUTES.ROOT} query={{ error: ERROR_NOT_ADMIN }} />
    }

    if (userStore.isCompanyAdmin && role === USER_ROLES.HSL_ADMIN) {
      return <Redirect route={ROUTES.COMPANY_ADMIN.SELECT} />
    }

    // ALlow supervisors to browser company admin screens so no redirect logic needed
    const isGuestCompanyAdmin =
      userStore.isSupervisor && role === USER_ROLES.ADMIN

    return (
      <>
        {isGuestCompanyAdmin && <GuestCompanyAdminBar />}
        <AcceptTOSModal
          isOpen={!isTosAccepted && !userStore.isSupervisor}
          setIsTosAccepted={setIsTosAccepted}
        />
        <Component isGuestCompanyAdmin={isGuestCompanyAdmin} {...rest} />
      </>
    )
  }
)

PrivateRouteRenderer.propTypes = {
  component: ComponentPropType.isRequired,
  role: RolePropType.isRequired,
}

export const RedirectToHome = () => {
  const [, i18n] = useTranslation()
  const to = `/${i18n.language || DEFAULT_LANGUAGE.code}`

  return <Navigate to={to} />
}

export const RedirectWithParamsAndQuery = ({ route, replace }) => {
  const [searchParams] = useSearchParams()
  const params = useParams()

  return (
    <Redirect
      route={route}
      params={params}
      query={Object.fromEntries(searchParams)}
      replace={replace}
    />
  )
}

RedirectWithParamsAndQuery.propTypes = {
  route: PropTypes.object.isRequired,
  replace: PropTypes.bool,
}

const route = (path, component, language, RouteComponent, rest) =>
  LANGUAGES.map(({ code }) => {
    const relativePath = path[code]
    const absolutePath = `/${language}/${path[code]}`

    return (
      <Route
        key={code}
        path={relativePath}
        element={
          <RouteComponent
            component={component}
            absolutePath={absolutePath}
            language={language}
            {...rest}
          />
        }
      />
    )
  })

export const basicRoute = (path, component, language) =>
  route(path, component, language, BasicRouteRenderer)

export const companyAdminRoute = (path, component, language) =>
  route(path, component, language, PrivateRouteRenderer, {
    role: USER_ROLES.ADMIN,
  })

export const supervisorRoute = (path, component, language) =>
  route(path, component, language, PrivateRouteRenderer, {
    role: USER_ROLES.HSL_ADMIN,
  })

export const redirect = (fromPath, toPath, replace, language) => (
  <Route
    path={fromPath[language]}
    element={<RedirectWithParamsAndQuery route={toPath} replace={replace} />}
  />
)
