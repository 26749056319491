import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from '../../modules/routes/components/Link'
import './Typography.scss'

const FONT_SIZE_ENUM = [
  'huge', // 24px mobile / 36px desktop
  'large', // 22px mobile / 30px desktop
  'medium', // 20px mobile / 24px desktop
  'mormal', // 18px mobile / 20px desktop
  'normal', // 16px mobile / 18px desktop
  'small', // 14px mobile / 16px desktop
  'tiny', // 12px mobile / 14px desktop
]

export const fontSizePropType = PropTypes.oneOf(FONT_SIZE_ENUM)

const fontSizeToCssClass = (size) => {
  if (!FONT_SIZE_ENUM.includes(size)) {
    console.warn(
      `Invalid font size: ${size}. Must be one of: ${FONT_SIZE_ENUM.join(
        ', '
      )}.`
    )
  }

  return `common__typography__${size}-font-size`
}

export const H1 = ({
  children,
  fontSize = 'large',
  className,
  style,
  ...props
}) => (
  <h1
    className={classnames(
      'common__typography__h1',
      fontSizeToCssClass(fontSize),
      className
    )}
    style={style}
    {...props}
  >
    {children}
  </h1>
)

H1.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  fontSize: fontSizePropType,
  className: PropTypes.string,
  style: PropTypes.object,
}

export const H2 = ({
  children,
  fontSize = 'medium',
  className,
  style,
  ...props
}) => (
  <h2
    className={classnames(
      'common__typography__h2',
      fontSizeToCssClass(fontSize),
      className
    )}
    style={style}
    {...props}
  >
    {children}
  </h2>
)

H2.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  fontSize: fontSizePropType,
  className: PropTypes.string,
  style: PropTypes.object,
}

export const H3 = ({
  children,
  fontSize = 'normal',
  className,
  style,
  ...props
}) => (
  <h3
    className={classnames(
      'common__typography__h3',
      fontSizeToCssClass(fontSize),
      className
    )}
    style={style}
    {...props}
  >
    {children}
  </h3>
)

H3.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  fontSize: fontSizePropType,
  className: PropTypes.string,
  style: PropTypes.object,
}

export const P = ({
  children,
  fontSize = 'normal',
  noMargin,
  className,
  style,
  ...props
}) => (
  <p
    className={classnames(
      'common__typography__p',
      { 'common__typography__no-margin': noMargin },
      fontSizeToCssClass(fontSize),
      className
    )}
    style={style}
    {...props}
  >
    {children}
  </p>
)

P.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  fontSize: fontSizePropType,
  noMargin: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
}

export const Small = ({
  children,
  fontSize = 'small',
  className,
  style,
  ...props
}) => (
  <small
    className={classnames(
      'common__typography__small',
      fontSizeToCssClass(fontSize),
      className
    )}
    style={style}
    {...props}
  >
    {children}
  </small>
)

Small.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  fontSize: fontSizePropType,
  className: PropTypes.string,
  style: PropTypes.object,
}

export const B = ({
  children,
  fontSize = 'normal',
  className,
  style,
  ...props
}) => (
  <strong
    className={classnames(
      'common__typography__b',
      fontSizeToCssClass(fontSize),
      className
    )}
    style={style}
    {...props}
  >
    {children}
  </strong>
)

B.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  fontSize: fontSizePropType,
  className: PropTypes.string,
  style: PropTypes.object,
}

export const Ol = ({
  items,
  fontSize = 'normal',
  className,
  style,
  ...props
}) => (
  <ol
    className={classnames('common__typography__ol', className)}
    style={style}
    {...props}
  >
    {items.map((item, index) => (
      <li
        key={index}
        className={classnames(
          'common__typography__ol__li',
          fontSizeToCssClass(fontSize)
        )}
      >
        {item}
      </li>
    ))}
  </ol>
)

Ol.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.node])
  ),
  fontSize: fontSizePropType,
  className: PropTypes.string,
  style: PropTypes.object,
}

const UL_COLOR_BLUE = 'blue'
const UL_COLOR_RED = 'red'

export const Ul = ({
  items,
  fontSize = 'normal',
  bulletColor,
  textColor,
  compact = false,
  className,
  ...props
}) => (
  <ul className={classnames('common__typography__ul', className)} {...props}>
    {items.map((item, index) => (
      <li
        key={index}
        className={classnames(
          'common__typography__ul__li',
          fontSizeToCssClass(fontSize),
          {
            'common__typography__ul__li--compact': compact,
            'common__typography__ul__li--blue-bullet':
              bulletColor === UL_COLOR_BLUE,
            'common__typography__ul__li--red-bullet':
              bulletColor === UL_COLOR_RED,
            'common__typography__ul__li--blue-text':
              textColor === UL_COLOR_BLUE,
            'common__typography__ul__li--red-text': textColor === UL_COLOR_RED,
          }
        )}
      >
        <div className="common__typography__ul__bullet"></div>
        <div className="common__typography__ul__content">
          <div className="common__typography__ul__value">{item}</div>
        </div>
      </li>
    ))}
  </ul>
)

Ul.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.node])
  ).isRequired,
  fontSize: fontSizePropType,
  compact: PropTypes.bool,
  bulletColor: PropTypes.oneOf([UL_COLOR_BLUE, UL_COLOR_RED]),
  textColor: PropTypes.oneOf([UL_COLOR_BLUE, UL_COLOR_RED]),
  className: PropTypes.string,
  style: PropTypes.object,
}

export const UlWithHeadings = ({
  items,
  fontSize = 'normal',
  compact = false,
  className,
  style,
  ...props
}) => (
  <ul
    className={classnames('common__typography__ul', className)}
    style={style}
    {...props}
  >
    {items.map(({ heading, value }, index) => (
      <li
        key={index}
        className={classnames(
          'common__typography__ul__li',
          fontSizeToCssClass(fontSize),
          {
            'common__typography__ul__li--compact': compact,
          }
        )}
      >
        <div className="common__typography__ul__bullet"></div>
        <div className="common__typography__ul__content">
          <div className="common__typography__ul__heading">{heading}</div>
          <div className="common__typography__ul__value">{value}</div>
        </div>
      </li>
    ))}
  </ul>
)

UlWithHeadings.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  fontSize: fontSizePropType,
  compact: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
}

export const BoldedList = ({
  items,
  fontSize = 'normal',
  className,
  style,
  ...props
}) => (
  <ul
    className={classnames('common__typography__bolded-list-ul', className)}
    style={style}
    {...props}
  >
    {items.map((item, index) => (
      <li
        key={index}
        className={classnames(
          'common__typography_bolded-list-ul__li',
          fontSizeToCssClass(fontSize)
        )}
      >
        {item}
      </li>
    ))}
  </ul>
)

BoldedList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
  fontSize: fontSizePropType,
  className: PropTypes.string,
  style: PropTypes.object,
}

export const CommaSeparatedUl = ({
  items,
  fontSize = 'small',
  className,
  style,
  ...props
}) => (
  <ul
    className={classnames('common__typography__comma-separated-ul', className)}
    style={style}
    {...props}
  >
    {items.map((item, index) => (
      <li
        key={index}
        className={classnames(
          'common__typography__comma-separated-ul__li',
          fontSizeToCssClass(fontSize)
        )}
      >
        {index + 1 < items.length ? `${item},` : item}
      </li>
    ))}
  </ul>
)

CommaSeparatedUl.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
  fontSize: fontSizePropType,
  className: PropTypes.string,
  style: PropTypes.object,
}

export const A = ({
  href,
  children,
  fontSize = 'normal',
  className,
  style,
  ...props
}) => (
  <a
    href={href}
    className={classnames(
      'common__typography__a',
      className,
      fontSizeToCssClass(fontSize)
    )}
    style={style}
    {...props}
  >
    {children}
  </a>
)

A.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node,
  fontSize: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
}

export const TextLink = ({ className, ...rest }) => (
  <Link
    className={classnames(className, 'common__typography__text-link')}
    {...rest}
  />
)

TextLink.propTypes = {
  className: PropTypes.string,
}
