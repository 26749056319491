import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'
import { pushTagEvent } from './tagManager'

const PushTagEventOnView = ({ event }) => {
  const ref = useRef(event)
  ref.current = event

  useEffect(() => {
    pushTagEvent(ref.current)
  }, [])

  return null
}

PushTagEventOnView.propTypes = {
  event: PropTypes.object.isRequired,
}

export default PushTagEventOnView
