import i18n from 'i18next'
import languageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { DEFAULT_LANGUAGE } from './constants'

import commonEn from '../i18next/common.en.json'
import commonFi from '../i18next/common.fi.json'
import commonSv from '../i18next/common.sv.json'

import anonymousEn from '../i18next/anonymous.en.json'
import anonymousFi from '../i18next/anonymous.fi.json'
import anonymousSv from '../i18next/anonymous.sv.json'

import beneficiaryEn from '../i18next/beneficiary.en.json'
import beneficiaryFi from '../i18next/beneficiary.fi.json'
import beneficiarySv from '../i18next/beneficiary.sv.json'

import companyEn from '../i18next/company.en.json'
import companyFi from '../i18next/company.fi.json'
import companySv from '../i18next/company.sv.json'

import supervisorEn from '../i18next/supervisor.en.json'
import supervisorFi from '../i18next/supervisor.fi.json'
import supervisorSv from '../i18next/supervisor.sv.json'

import selfServiceEn from '../i18next/self-service.en.json'
import selfServiceFi from '../i18next/self-service.fi.json'
import selfServiceSv from '../i18next/self-service.sv.json'
import config from './config'

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: DEFAULT_LANGUAGE.code,
    resources: {
      fi: {
        COMMON: commonFi,
        ANONYMOUS: anonymousFi,
        BENEFICIARY: beneficiaryFi,
        COMPANY: companyFi,
        SUPERVISOR: supervisorFi,
        SELF_SERVICE: selfServiceFi,
      },
      sv: {
        COMMON: commonSv,
        ANONYMOUS: anonymousSv,
        BENEFICIARY: beneficiarySv,
        COMPANY: companySv,
        SUPERVISOR: supervisorSv,
        SELF_SERVICE: selfServiceSv,
      },
      en: {
        COMMON: commonEn,
        ANONYMOUS: anonymousEn,
        BENEFICIARY: beneficiaryEn,
        COMPANY: companyEn,
        SUPERVISOR: supervisorEn,
        SELF_SERVICE: selfServiceEn,
      },
    },
    interpolation: {
      escapeValue: false,
      defaultVariables: {
        hslHomeUrl: config.HSL_HOME_URL,
      },
    },
    detection: {
      order: ['localStorage'],
      lookupLocalStorage: 'language',
    },
  })

i18n.on('languageChanged', (lng) => (document.documentElement.lang = lng))

export default i18n
