import { generatePath, matchPath } from 'react-router'

export const ROUTE_LANGUAGE_PATTERN = '/:lang'

export const ROOT = 'ROOT'
export const AUTHENTICATION = 'AUTHENTICATION'
export const BENEFICIARY = 'BENEFICIARY'
export const SELF_SERVICE = 'SELF_SERVICE'
export const COMPANY_ADMIN = 'COMPANY_ADMIN'
export const SUPERVISOR = 'SUPERVISOR'
export const ERROR = 'ERROR'

const ROUTES = {
  [ROOT]: {
    fi: '',
    sv: '',
    en: '',
  },
  [AUTHENTICATION]: {
    SERVICE_UNAVAILABLE: {
      fi: 'kirjautuminen-ei-onnistu',
      sv: 'inloggning-otillganglig',
      en: 'login-unavailable',
    },
    UNAUTHORIZED: {
      fi: 'kirjautuminen/paasy-estetty',
      sv: 'autentisering/atkomst-nekad',
      en: 'auth/access-denied',
    },
  },
  [ERROR]: {
    GENERIC_ERROR: {
      fi: 'virhe',
      sv: 'fel',
      en: 'error',
    },
  },
  [BENEFICIARY]: {
    REGISTRATION: {
      fi: 'luo-uusi-yritystunnus',
      sv: 'skapa-ett-nytt-foretags-id',
      en: 'create-a-new-business-id',
    },
    INVALID_LINK: {
      fi: 'virheellinen-linkki',
      sv: 'ogiltig-lank',
      en: 'invalid-link',
    },
    AUTH_SUCCESS: {
      fi: 'kirjautuminen-onnistui',
      sv: 'inloggningen-lyckades',
      en: 'login-successful',
    },
    VERIFICATION: {
      fi: 'vahvistuskoodi',
      sv: 'bekraftelsekod',
      en: 'confirmation-code',
    },
    VERIFICATION_SUCCESS: {
      fi: 'vahvistus-onnistui',
      sv: 'bekraftelsen-lyckades',
      en: 'confirmation-successful',
    },
    STRONG_AUTHENTICATION_SUCCESS: {
      fi: 'vahva-tunnistautuminen-onnistui',
      sv: 'stark-autentisering-lyckades',
      en: 'strong-authentication-successful',
    },
    STRONG_AUTHENTICATION_ERROR: {
      fi: 'virhe-vahvassa-tunnistautumisessa',
      sv: 'stark-autentisering-fel',
      en: 'strong-authentication-error',
    },
    BENEFICIARY_SUCCESS_SCREEN: {
      fi: 'edunsaaja/valmis',
      sv: 'formanstagare/fardig',
      en: 'beneficiary/completed',
    },
    BENEFICIARY_SUCCESS_SCREEN_MOCK: {
      fi: 'edunsaaja/valmis/mock',
      sv: 'formanstagare/fardig/mock',
      en: 'beneficiary/completed/mock',
    },
  },
  [SELF_SERVICE]: {
    HOME: {
      fi: 'tilauslomake',
      sv: 'bestallningsformular',
      en: 'order-form',
    },
    APPLICATION_CONFIRMATION_SCREEN: {
      fi: 'tilauslomake/tarkasta-tilauksen-tiedot',
      sv: 'bestallningsformular/granska-bestallningsinformation',
      en: 'order-form/review-order-details',
    },
    APPLICATION_SENT: {
      fi: 'tilauslomake/tilaus-lahetetty',
      sv: 'bestallningsformular/bestallning-skickad',
      en: 'order-form/order-sent',
    },
    NEW_COMPANY_ADMIN: {
      fi: 'tilauslomake/uusi-paakayttaja',
      sv: 'bestallningsformular/ny-huvudanvandare',
      en: 'order-form/new-administrator',
    },
    AUTH_SUCCESS: {
      fi: 'tilauslomake/todennus-onnistui',
      sv: 'bestallningsformular/autentisaringen-lyckades',
      en: 'order-form/authentication-successful',
    },
    VERIFICATION: {
      fi: 'tilauslomake/vahvistuskoodi',
      sv: 'bestallningsformular/bekraftelsekod',
      en: 'order-form/confirmation-code',
    },
    STRONG_AUTHENTICATION: {
      fi: 'tilauslomake/vahva-tunnistautuminen',
      sv: 'bestallningsformular/stark-autentisering',
      en: 'order-form/strong-authentication',
    },
    STRONG_AUTHENTICATION_SUCCESS: {
      fi: 'tilauslomake/vahva-tunnistautuminen-onnistui',
      sv: 'bestallningsformular/stark-autentisering-lyckades',
      en: 'order-form/strong-authentication-successful',
    },
    STRONG_AUTHENTICATION_ERROR: {
      fi: 'tilauslomake/virhe-vahvassa-tunnistautumisessa',
      sv: 'bestallningsformular/starkt-autentiseringsfel',
      en: 'order-form/strong-authentication-error',
    },
  },
  [COMPANY_ADMIN]: {
    SELECT: {
      fi: 'organisaatio/valitse-organisaatio',
      sv: 'organisation/valj-organisation',
      en: 'organization/select-company',
    },
    COMPANY_PORTAL: {
      fi: 'organisaatio/:companyId/portaali',
      sv: 'organisation/:companyId/portal',
      en: 'organization/:companyId/portal',
    },
    BUSINESS_TRIP__ROOT: {
      fi: 'organisaatio/:companyId/asiointiliput',
      sv: 'organisation/:companyId/affarsbiljetter',
      en: 'organization/:companyId/business-tickets',
    },
    BUSINESS_TRIP__ADD_BENEFICIARIES: {
      fi: 'organisaatio/:companyId/asiointiliput/lisaa-edunsaajia',
      sv: 'organisation/:companyId/affarsbiljetter/lagga-till-formanstagare',
      en: 'organization/:companyId/business-tickets/add-beneficiaries',
    },
    BUSINESS_TRIP__BENEFICIARIES: {
      fi: 'organisaatio/:companyId/asiointiliput/edunsaajat',
      sv: 'organisation/:companyId/affarsbiljetter/formanstagare',
      en: 'organization/:companyId/business-tickets/beneficiaries',
    },
    BUSINESS_TRIP__BENEFICIARY: {
      fi: 'organisaatio/:companyId/asiointiliput/edunsaajat/:beneficiaryId',
      sv: 'organisation/:companyId/affarsbiljetter/formanstagare/:beneficiaryId',
      en: 'organization/:companyId/business-tickets/beneficiaries/:beneficiaryId',
    },
    BUSINESS_TRIP__BENEFIT_SETTINGS: {
      fi: 'organisaatio/:companyId/asiointiliput/saldon-asetukset',
      sv: 'organisation/:companyId/affarsbiljetter/installningar-for-reskassa',
      en: 'organization/:companyId/business-tickets/budget-settings',
    },
    BUSINESS_TRIP__BENEFIT_SETTINGS_PAYMENT_FREQUENCY: {
      fi: 'organisaatio/:companyId/asiointiliput/saldon-asetukset/saldon-jakelurytmi',
      sv: 'organisation/:companyId/affarsbiljetter/installningar-for-reskassa/betalningsintervall',
      en: 'organization/:companyId/business-tickets/budget-settings/payment-frequency',
    },
    BUSINESS_TRIP__BENEFIT_SETTINGS_BUDGET_RESET: {
      fi: 'organisaatio/:companyId/asiointiliput/saldon-asetukset/saldon-nollautuminen',
      sv: 'organisation/:companyId/affarsbiljetter/installningar-for-reskassa/nollstallning-av-beloppet',
      en: 'organization/:companyId/business-tickets/budget-settings/budget-reset',
    },
    ROOT: {
      fi: 'organisaatio/:companyId/tyomatkaetu',
      sv: 'organisation/:companyId/arbetsreseforman',
      en: 'organization/:companyId/commuter-benefit',
    },
    ADD_BENEFICIARIES: {
      fi: 'organisaatio/:companyId/tyomatkaetu/lisaa-edunsaajia',
      sv: 'organisation/:companyId/arbetsreseforman/lagga-till-formanstagare',
      en: 'organization/:companyId/commuter-benefit/add-beneficiaries',
    },
    SCHOOL_TICKET_ROOT: {
      fi: 'organisaatio/:companyId/koululaisliput',
      sv: 'organization/:companyId/skolbiljetter',
      en: 'organisation/:companyId/school-tickets',
    },
    SCHOOL_TICKET_ADD_BENEFICIARIES: {
      fi: 'organisaatio/:companyId/koululaisliput/lisaa-edunsaajia',
      sv: 'organization/:companyId/skolbiljetter/lagga-till-formanstagare',
      en: 'organisation/:companyId/school-tickets/add-beneficiaries',
    },
    SCHOOL_TICKET_BENEFICIARIES: {
      fi: 'organisaatio/:companyId/koululaisliput/edunsaajat',
      sv: 'organization/:companyId/skolbiljetter/formanstagare',
      en: 'organisation/:companyId/school-tickets/beneficiaries',
    },
    SCHOOL_TICKET_SINGLE_BENEFICIARY: {
      fi: 'organisaatio/:companyId/koululaisliput/edunsaajat/:beneficiaryId',
      sv: 'organization/:companyId/skolbiljetter/formanstagare/:beneficiaryId',
      en: 'organisation/:companyId/school-tickets/beneficiaries/:beneficiaryId',
    },
    SCHOOL_TICKET_SEASONS_VIEW: {
      fi: 'organisaatio/:companyId/koululaisliput/lukuvuodet',
      sv: 'organization/:companyId/skolbiljetter/terminer',
      en: 'organisation/:companyId/school-tickets/seasons',
    },
    SCHOOL_TICKET_EDIT_SEASON_VIEW: {
      fi: 'organisaatio/:companyId/koululaisliput/lukuvuodet/:calendarId',
      sv: 'organization/:companyId/skolbiljetter/terminer/:calendarId',
      en: 'organisation/:companyId/school-tickets/season/:calendarId',
    },
    SCHOOL_TICKET_CREATE_SEASON_VIEW: {
      fi: 'organisaatio/:companyId/koululaisliput/lukuvuodet/lisaa-uusi',
      sv: 'organization/:companyId/skolbiljetter/terminer/lagga-till',
      en: 'organisation/:companyId/school-tickets/season/add-new',
    },
    SCHOOL_TICKET_REPORTS: {
      fi: 'organisaatio/:companyId/koululaisliput/raportit',
      sv: 'organisation/:companyId/school-tickets/rapporter',
      en: 'organization/:companyId/skolbiljetter/reports',
    },
    SCHOOL_TICKET_EVENT_LOG: {
      fi: 'organisaatio/:companyId/koululaisliput/tapahtumaloki',
      sv: 'organization/:companyId/skolbiljetter/handelselog',
      en: 'organisation/:companyId/school-tickets/event-log',
    },
    TICKET_ORDERS__ROOT: {
      fi: 'organisaatio/:companyId/lipputilaukset',
      sv: 'organisation/:companyId/biljettbestallningar',
      en: 'organization/:companyId/ticket-orders',
    },
    TICKET_ORDERS__MOBILE_TICKET_ORDER: {
      fi: 'organisaatio/:companyId/lipputilaukset/lippukoodit-sovellukseen',
      sv: 'organisation/:companyId/biljettbestallningar/biljettkoder-for-applikationen',
      en: 'organization/:companyId/ticket-orders/ticket-codes-for-hsl-app',
    },
    TICKET_ORDERS__PAPER_CARD_TICKET_ORDER: {
      fi: 'organisaatio/:companyId/lipputilaukset/paperinen-kertakortti',
      sv: 'organisation/:companyId/biljettbestallningar/engangskort-pa-papper',
      en: 'organization/:companyId/ticket-orders/disposable-paper-cards',
    },
    REPORTS__ROOT: {
      fi: 'organisaatio/:companyId/raportit',
      sv: 'organisation/:companyId/rapporter',
      en: 'organization/:companyId/reports',
    },
    REPORTS__SUMMARY: {
      fi: 'organisaatio/:companyId/raportit/kooste',
      sv: 'organisation/:companyId/rapporter/sammanstallning',
      en: 'organization/:companyId/reports/summary',
    },
    REPORTS__EXPORTS: {
      fi: 'organisaatio/:companyId/raportit/tulosta-raportteja',
      sv: 'organisation/:companyId/rapporter/ladda-ner-rapporter',
      en: 'organization/:companyId/reports/download-reports',
    },
    REVIEW_TERMS_OF_SERVICE: {
      fi: 'organisaatio/:companyId/tyomatkaetu/hyvaksy-kayttoehdot',
      sv: 'organisation/:companyId/arbetsreseforman/godkann-andvandarvillkoren',
      en: 'organization/:companyId/commuter-benefit/agree-to-the-terms-of-use',
    },
    BENEFICIARIES: {
      fi: 'organisaatio/:companyId/tyomatkaetu/edunsaajat',
      sv: 'organisation/:companyId/arbetsreseforman/formanstagare',
      en: 'organization/:companyId/commuter-benefit/beneficiaries',
    },
    BENEFICIARY: {
      fi: 'organisaatio/:companyId/tyomatkaetu/edunsaajat/:beneficiaryId',
      sv: 'organisation/:companyId/arbetsreseforman/formanstagare/:beneficiaryId',
      en: 'organization/:companyId/commuter-benefit/beneficiaries/:beneficiaryId',
    },
    COMMUTER_BENEFIT__BENEFIT_SETTINGS: {
      fi: 'organisaatio/:companyId/tyomatkaetu/saldon-asetukset',
      sv: 'organisation/:companyId/arbetsreseforman/installningar-for-reskassa',
      en: 'organization/:companyId/commuter-benefit/budget-settings',
    },
    COMMUTER_BENEFIT__BENEFIT_SETTINGS_PAYMENT_FREQUENCY: {
      fi: 'organisaatio/:companyId/tyomatkaetu/saldon-asetukset/saldon-jakelurytmi',
      sv: 'organisation/:companyId/arbetsreseforman/installningar-for-reskassa/betalningsintervall',
      en: 'organization/:companyId/commuter-benefit/budget-settings/payment-frequency',
    },
    COMMUTER_BENEFIT__BENEFIT_SETTINGS_BUDGET_RESET: {
      fi: 'organisaatio/:companyId/tyomatkaetu/saldon-asetukset/saldon-nollautuminen',
      sv: 'organisation/:companyId/arbetsreseforman/installningar-for-reskassa/nollstallning-av-beloppet',
      en: 'organization/:companyId/commuter-benefit/budget-settings/budget-reset',
    },
    ABOUT: {
      fi: 'organisaatio/:companyId/organisaation-tiedot',
      sv: 'organisation/:companyId/organisationsuppgifter',
      en: 'organization/:companyId/organization-information',
    },
    EVENT_LOG: {
      fi: 'organisaatio/:companyId/tapahtumaloki',
      sv: 'organisation/:companyId/handelselog',
      en: 'organization/:companyId/event-log',
    },
    INSTRUCTIONS: {
      fi: 'organisaatio/:companyId/ohjeet',
      sv: 'organisation/:companyId/anvisningar',
      en: 'organization/:companyId/instructions',
    },
    MY_PROFILE: {
      fi: 'organisaatio/:companyId/omat-tiedot',
      sv: 'organisation/:companyId/min-information',
      en: 'organization/:companyId/my-information',
    },
    DEV_TEST: {
      fi: 'dev/testaus',
      sv: 'dev/testning',
      en: 'dev/testing',
    },
    DEV_TEST_WITH_PARAM: {
      fi: 'dev/testaus/:id',
      sv: 'dev/testning/:id',
      en: 'dev/testing/:id',
    },
  },
  [SUPERVISOR]: {
    LANDING: {
      fi: 'hsladmin/kirjaudu',
      sv: 'hsladmin/logga-in',
      en: 'hsladmin/login',
    },
    COMPANIES: {
      fi: 'hsladmin/organisaatiot',
      sv: 'hsladmin/organisationer',
      en: 'hsladmin/organizations',
    },
    COMPANY: {
      fi: 'hsladmin/organisaatiot/:companyId',
      sv: 'hsladmin/organisationer/:companyId',
      en: 'hsladmin/organizations/:companyId',
    },
    ADD_NEW_COMPANY: {
      fi: 'hsladmin/lisaa-uusi-organisaatio',
      sv: 'hsladmin/lagg-till-en-ny-organisation',
      en: 'hsladmin/add-new-organization',
    },
    EVENTS: {
      fi: 'hsladmin/tapahtumaloki',
      sv: 'hsladmin/handelselog',
      en: 'hsladmin/event-log',
    },
    EXPORTS: {
      fi: 'hsladmin/lataa',
      sv: 'hsladmin/exportera',
      en: 'hsladmin/export',
    },
  },
}

const pathToMultilingualRoutes = (originalPath) => {
  let translatedRoutes

  const findMultilingualRoutes = (path, map) => {
    if (typeof map === 'undefined') {
      return
    }

    Object.keys(map).forEach((key) => {
      const child = map[key]
      const values = Object.values(child)
      const hasStringValues = values.some((value) => typeof value === 'string')

      if (values.includes(path)) {
        translatedRoutes = child
        return
      }

      if (!translatedRoutes && typeof child === 'object' && !hasStringValues) {
        return findMultilingualRoutes(path, child)
      }
    })

    return translatedRoutes
  }

  findMultilingualRoutes(getPathWithoutLanguage(originalPath), ROUTES)

  return translatedRoutes
}

const getPathWithoutLanguage = (path) => {
  const [, /* strip language param */ ...partsOfRoute] = path
    .split('/')
    .filter(Boolean)

  return `${partsOfRoute.join('/')}`
}

export const multilingualRoutesToPath = (
  multilingualRoutes,
  params = {},
  query = {},
  language
) => {
  const pattern = multilingualRoutes[language]

  const urlSearchParams = new URLSearchParams(query)
  const urlSearchParamsAsString = urlSearchParams.toString()

  const path = generatePath(
    pattern === '/' ? '' /* remove trailing slash if root route */ : pattern,
    params
  )

  return `/${language}${path ? '/' : ''}${path}${
    urlSearchParamsAsString ? '?' : ''
  }${urlSearchParamsAsString}`
}

export const translatePathToLanguage = (path, params, query = {}, language) => {
  const patterns = pathToMultilingualRoutes(path)

  return multilingualRoutesToPath(patterns, params, query, language)
}

export const isCorrectPathForLanguage = (path, language) => {
  const translatedRoutes = pathToMultilingualRoutes(path)
  const currentRoute = getPathWithoutLanguage(path)
  const correctRoute = translatedRoutes[language]

  return currentRoute === correctRoute
}

export const isSameRoute = ({
  multilingualRoutes,
  params = {},
  targetPath,
  language,
  matchWholePath = true,
}) => {
  const originalPath = multilingualRoutesToPath(
    multilingualRoutes,
    params,
    {},
    language
  )

  return Boolean(
    matchPath({ path: originalPath, end: matchWholePath }, targetPath)
  )
}

// Resolves the hierarchical route group (e.g. SUPERVISOR or SELF_SERVICE) of the given pathname.
export const getRouteGroupByPathname = (pathname) => {
  const groupKeys = Object.keys(ROUTES)

  for (const groupKey of groupKeys) {
    const routeKeys = Object.keys(ROUTES[groupKey])

    for (const routeKey of routeKeys) {
      if (matchPath({ path: `/${routeKey}` }, pathname)) {
        return groupKey
      }

      const pathKeys = Object.keys(ROUTES[groupKey][routeKey])

      for (const pathKey of pathKeys) {
        if (
          matchPath(
            { path: `/${pathKey}/${ROUTES[groupKey][routeKey][pathKey]}` },
            pathname
          )
        ) {
          return groupKey
        }
      }
    }
  }
}

// Swaps the language prefix ONLY but does not alter the rest of the path.
// This can be used for redirects when switching the language since our routing
// auto-corrects multilingual paths based on the prefix.
export const swapLanguagePrefix = (location, language) => {
  const partsOfFullPath = `${location.pathname}${location.search}`.split('/')

  partsOfFullPath[1] = language // second item = language prefix

  return partsOfFullPath.join('/')
}

export default ROUTES
