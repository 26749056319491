import ContainerSpinner from '@hsl-fi/container-spinner'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import apiStore from '../stores/apiStore'
import userStore from '../stores/userStore'

const Authenticated = ({ children }) => {
  useEffect(() => {
    const fetchUser = async () => {
      const isAlreadyAuthenticated = Boolean(
        userStore.user && userStore.user.sub
      )

      if (isAlreadyAuthenticated) {
        return
      }

      try {
        const response = await apiStore.fetchUser()
        userStore.setUser(response)
      } catch (e) {
        userStore.setUser({}, true)
      }
    }

    fetchUser()
  }, [])

  const isAuthenticated = Boolean(userStore.user && userStore.user.sub)

  return (
    <ContainerSpinner visible={!isAuthenticated}>
      {isAuthenticated ? children : null}
    </ContainerSpinner>
  )
}

Authenticated.propTypes = {
  children: PropTypes.node,
}

export default observer(Authenticated)
