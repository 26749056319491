import { useRef } from 'react'

const useOnChange = (onChange, value, initialValue = value) => {
  const previousValue = useRef(initialValue)
  if (value !== previousValue.current) {
    onChange(previousValue.current)
    previousValue.current = value
  }
}

export default useOnChange
