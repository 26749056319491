import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import ARROW_DOWN_ICON from '../../assets/icons/arrow-down.svg'
import './ToggleArrow.scss'

const ToggleArrow = ({ expanded, height = '10px', alt, style, className }) => {
  const [t] = useTranslation()

  return (
    <img
      src={ARROW_DOWN_ICON}
      className={classnames(
        'common__toggle-arrow',
        {
          'common__toggle-arrow--expanded': expanded,
        },
        className
      )}
      alt={
        alt ||
        t(
          expanded
            ? 'COMMON:TOGGLE_ARROW.ARROW_UP'
            : 'COMMON:TOGGLE_ARROW.ARROW_DOWN'
        )
      }
      style={{
        height,
        ...style,
      }}
    />
  )
}

ToggleArrow.propTypes = {
  expanded: PropTypes.bool,
  height: PropTypes.string,
  alt: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
}

export default ToggleArrow
