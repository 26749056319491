import classnames from 'classnames'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import './InputLabel.scss'

const InputLabel = forwardRef(
  (
    {
      required,
      optional,
      htmlFor,
      children,
      className,
      style,
      labelSuffix,
      ...props
    },
    ref
  ) => {
    const [t] = useTranslation()

    return (
      <label
        ref={ref}
        htmlFor={htmlFor}
        className={classnames('common__input-label', className)}
        style={style}
        {...props}
      >
        {children}
        {Boolean(required) && (
          <span className="common__input-label__required">*</span>
        )}
        {optional && (
          <small className="common__input-label__optional">
            ({t('COMMON:OPTIONAL')})
          </small>
        )}
        {labelSuffix}
      </label>
    )
  }
)

InputLabel.displayName = 'InputLabel'

InputLabel.propTypes = {
  required: PropTypes.bool,
  optional: PropTypes.bool,
  htmlFor: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
  style: PropTypes.object,
  labelSuffix: PropTypes.node,
}

export default InputLabel
