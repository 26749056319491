import companiesQueryKeys from '../../../companiesQueryKeys'

const benefitsQueryKeys = {
  root: (companyId) => [...companiesQueryKeys.single(companyId), 'benefits'],

  beneficiariesRoot: (companyId) => [
    ...benefitsQueryKeys.root(companyId),
    'beneficiaries',
  ],
  invitationsList: (companyId, params, options) => [
    ...benefitsQueryKeys.beneficiariesRoot(companyId),
    'invitationslist',
    params,
    options,
  ],
  beneficiariesList: (companyId, options) => [
    ...benefitsQueryKeys.beneficiariesRoot(companyId),
    'beneficiariesList',
    options,
  ],
  validBeneficiaryEmails: (companyId, emails) => [
    ...benefitsQueryKeys.beneficiariesRoot(companyId),
    'validBeneficiaryEmails',
    emails,
  ],
  validBeneficiaryEmployeeIds: (companyId, employeeIds) => [
    ...benefitsQueryKeys.beneficiariesRoot(companyId),
    'validBeneficiaryEmployeeIds',
    employeeIds,
  ],

  schoolBeneficiariesRoot: (companyId) => [
    ...benefitsQueryKeys.root(companyId),
    'schoolBeneficiaries',
  ],
  schoolBeneficiaryDetails: (companyId, beneficiaryId) => [
    ...benefitsQueryKeys.schoolBeneficiariesRoot(companyId),
    'beneficiary',
    beneficiaryId,
  ],
  schoolBeneficiaryValidityDays: (companyId, beneficiaryId, year, month) => [
    ...benefitsQueryKeys.schoolBeneficiaryDetails(companyId, beneficiaryId),
    'validityDays',
    { year, month },
  ],
  schoolBeneficiariesList: (companyId, options) => [
    ...benefitsQueryKeys.schoolBeneficiariesRoot(companyId),
    'list',
    options,
  ],
  settings: (companyId) => [...benefitsQueryKeys.root(companyId), 'settings'],
}

export default benefitsQueryKeys
