import { matchRoutes, useLocation } from 'react-router-dom'
import { router } from '../../modules/App'
import useOnChange from '../hooks/useOnChange'
import { pushTagEvent } from './tagManager'

export const usePageViewTagEvents = () => {
  const path = useLocation().pathname
  useOnChange(
    (previousPath) => {
      pushTagEvent({
        event: 'virtual_page_view',
        pageTitle: document.title,
        pageUrl: getPathMatch(path),
        previousPageUrl: getPathMatch(previousPath),
      })
    },
    path,
    null
  )
}

/** Get current path without variables and query params */
const getPathMatch = (path) => {
  if (!path) {
    return
  }
  const matchedPath = matchRoutes(router.routes, path)
    .reduce((prev, curr) => `${prev}/${curr.route.path ?? ''}`, '/')
    .replace(/\/+/g, '/')
  return `${location.protocol}//${location.host}${matchedPath}`
}
