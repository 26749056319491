import * as Sentry from '@sentry/react'
import { observer } from 'mobx-react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { ExternalLinkButton } from '../../../common/components/Button'
import Page from '../../../common/components/Page'
import { H1, P } from '../../../common/components/Typography'
import WhiteBox from '../../../common/components/WhiteBox'
import apiStore from '../../../common/stores/apiStore'
import uiMessageStore from '../../../common/stores/uiMessageStore'

const StrongAuthenticationErrorScreen = () => {
  const [t] = useTranslation()
  const [searchParams] = useSearchParams()
  const reason = searchParams.get('reason')
  const serverError = t('COMMON:ERRORS.SERVER_ERROR')

  useEffect(() => {
    const sendErrorReportToSentry = async () => {
      try {
        const user = await apiStore.fetchUser()

        Sentry.captureEvent({
          message: 'Beneficiary strong authentication error',
          extra: {
            userId: user.sub,
          },
        })
      } catch (e) {
        uiMessageStore.addError(serverError, e)
      }
    }

    sendErrorReportToSentry()
  }, [serverError])

  return (
    <Page>
      <WhiteBox>
        <H1>{t('BENEFICIARY:STRONG_AUTHENTICATION_ERROR_SCREEN.TITLE')}</H1>
        {reason === 'outside-hsl-zone' && (
          <P>
            {t(
              'BENEFICIARY:STRONG_AUTHENTICATION_ERROR_SCREEN.YOU_ARE_NOT_WITHIN_HSL_ZONE'
            )}
          </P>
        )}
        <P>
          {t(
            'BENEFICIARY:STRONG_AUTHENTICATION_ERROR_SCREEN.PLEASE_CONTACT_YOUR_ADMIN'
          )}
        </P>
        <ExternalLinkButton secondary href={t('COMMON:HSL_HOME_URL')}>
          {t('COMMON:GO_TO_HSL_FI')}
        </ExternalLinkButton>
      </WhiteBox>
    </Page>
  )
}

export default observer(StrongAuthenticationErrorScreen)
